<template>
	<v-container>
		<v-row
			align="start"
			class="my-5"
		>
			<v-col>
				<h2 class="text-center mb-4">¿Qué es Iduam?</h2>
				<p class="text-justify mb-6">Es un asistente que monitorea parámetros de salud como la Presión Arterial, Ritmo Cardíaco, Saturación de Oxígeno en forma constante y proactiva.</p>
				<div
					class="mx-auto text-center"
					style="max-width: 500px;">
					<iframe width="100%" height="315" src="https://www.youtube.com/embed/RW_EclTXHxk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<v-btn
					depressed
					color="#0064F1"
					dark
					block
					class="mt-6"
					href="/"
					target="_blank"
				>
					<v-icon class="mr-3">
						mdi-card-account-mail-outline
					</v-icon>
					Conoce más sobre Iduam
				</v-btn>
			</v-col>
			<!-- <v-divider vertical class="mx-6" v-if="!$vuetify.breakpoint.mobile"></v-divider>
			<v-col>
				<h2 class="text-center mb-4">Preguntas Frecuentes</h2>

				<v-expansion-panels accordion>
					
					<v-expansion-panel>
						<v-expansion-panel-header class="font-weight-bold">¿Cuanto podría ganar?</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p>Ganarás $30.000 CLP por cada venta que realices con tu código de referido.</p>
							<h4>Ejemplo</h4>
							<p>Supongamos que vendes 10 packs Iduam al mes. Esto significa que cada mes recibirás $300.000 CLP en tu cuenta bancaria.</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					
					<v-expansion-panel>
						<v-expansion-panel-header class="font-weight-bold">¿Por qué ventas ganaré comisión?</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p>Ganarás comisión siempre que se registre una venta con tu código de referido.</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					
					<v-expansion-panel>
						<v-expansion-panel-header class="font-weight-bold">¿Cuando me pagarán mis comisiones?</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p>Una vez a la semana se harán las transferencias a tu cuenta bancaria.</p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					
					<v-expansion-panel>
						<v-expansion-panel-header class="font-weight-bold">¿El descuento del $80.000 hasta cuando es válido?</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p>Este descuento es una oferta especial para ti y está orientado para que conozcas el producto que venderás. <b>Es válido sólo por una compra.</b></p>
						</v-expansion-panel-content>
					</v-expansion-panel>
					
					<v-expansion-panel>
						<v-expansion-panel-header class="font-weight-bold">¿Cómo me inscribo para ser vendedor?</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p>Completa tus datos en el formulario al final de está página y te enviaremos toda la información que necesitas.</p>
						</v-expansion-panel-content>
					</v-expansion-panel>

				</v-expansion-panels>

			</v-col> -->
		</v-row>
	</v-container>
</template>

<script>

export default {
  name: "Know",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {},
  mounted() {
  },
};
</script>

<style scoped>
</style>