<template>
  <div>
    <Header />
    <!--             HERO -->
    <header style="padding-top: 80px" class="v1 relative-box" id="home-area">
      <div class="HeroMessage">
        <!--         <p class="HeroMessage__Text">Por la compra de una cámara SOSCAM, tienes acceso gratis a todas las funcionalidades de SOSClick.</p> -->
      </div>

      <div class="image-side-box right-box hidden-xs hidden-sm box-bg">
        <img
          src="../../assets/images/T-68.png"
          class="layer bg-camara"
          style="width: 46%; max-width: 280px"
          data-depth="0.35"
          alt="Iduam T-68"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-5">
            <div class="text-box">
              <h2 style="font-weight: 800; font-size: 50px; color: #0064f1">
                Iduam T-68
                <span
                  style="
                    font-weight: 400;
                    display: block;
                    font-size: 20px;
                    color: #0064f1;
                  "
                  >Adquiere la Tuya</span
                >
              </h2>
              <div class="hr"></div>
              <h3 style="font-weight: 800; color: #0064f1; margin-top: 28px">
                MONITOREAMOS CADA LATIDO
              </h3>
              <p style="max-width: 580px; text-align: justify">
                La Iduam T-68 te permite medir tu Ritmo Cardíaco, Presión
                Arterial y Saturación de Oxígeno<!-- y Temperatura -->.
              </p>
              <h3
                :class="price ? 'scale-animation' : 'scale-animation-0'"
                style="
                  font-size: 24px;
                  margin-top: 8px;
                  transition: all 0.7s ease;
                "
              >
                Ahora
                <span
                  >${{ priceDiscount | formatNumber }}!
                  <span
                    v-if="discount"
                    style="font-size: 20px; text-decoration: line-through"
                    >${{ price | formatNumber }}</span
                  ></span
                >
              </h3>
              <!--               <div v-else class="loader"></div> -->

              <!--               <div class="space-30"></div> -->
              <div class="hero-buttons-container mt-2">
                <button
                  style="z-index: 98"
                  @click="$router.push('/checkout')"
                  class="main-btn"
                >
                  Comprar
                </button>

                <a href="#info" style="z-index: 1"
                  ><button style="color: #0064f1">Más Información</button></a
                >
              </div>
              <img
                src="../../assets/images/webpay.png"
                style="width: 100px; margin-top: 12px; margin-left: 22px"
                alt="webpay"
              />
            </div>
          </div>
        </div>
      </div>
    </header>

        <!--         POR QUE Iduam T-68 -->
    <div id="info" class="container" style="margin: 0 auto; scroll-margin-top: 90px;">
      <section class="Porque">
        <h2 class="Porque__Title">¿por qué Iduam T-68?</h2>

        <div class="Porque__Container" style="background-color: #F7F7F7;">
          <div
            class="Porque__Container__Item"
            v-for="(item, i) in features"
            :key="i"
          >
            <div class="container-title-item">
              <h3 class="Porque__Container__Item__Number">{{ item.number }}</h3>
              <v-icon
                v-if="item.number !== 8"
                class="Porque__Container__Item__Icon"
                >{{ item.icon }}</v-icon
              >
              <div v-else style="display:flex; align-items: center;">
                <v-icon class="Porque__Container__Item__Icon">mdi-apple</v-icon>
                <v-icon class="Porque__Container__Item__Icon"
                  >mdi-android</v-icon
                >
              </div>
            </div>

            <h4 class="Porque__Container__Item__Title">{{ item.title }}</h4>
            <p class="Porque__Container__Item__Paragraph">
              {{ item.paragraph }}
            </p>
          </div>

          <div class="Porque__Container__Item Porque__Container__Item--button" style="display: flex; align-items: center;flex-direction: column;">
            <button style="z-index: 98;" class="main-btn"  @click="$router.push('/checkout')">Comprar</button>
                <img src="../../assets/images/webpay.png" style="width: 100px;margin-top: 12px;" alt="webpay">
          </div>
        </div>
      </section>
    </div>

    <!--         OFERTA -->
    <section
      class="intro-area about-section over-hidden oferta-bg mt-10"
      id="about-area"
      style="margin-top: 20px; width: 100vw;margin:0; padding: 0;"
    >
      <div class="container oferta-container">
        <div class="Oferta">
          <div style="position:relative;">
            <figure class="play-image wow zoomIn">
              <img
                src="../../assets/images/T-68.png"
                class="bg-camara Oferta__Img"
                alt=""
              />
            </figure>
            <a
              href="https://www.youtube.com/watch?v=eFUyZAGw0KY"
              data-lity
              class="play-bttn"
              style="z-index: 98;"
              ><i class="fa fa-play"></i
            ></a>
          </div>

          <div class="text-box text-white  Oferta__Content">
            <h2 class="title">
              OFERTA EXCLUSIVA <br />
              ¡PROTEGE LO QUE MÁS QUIERES POR SOLO ${{priceDiscount | formatNumber}}! <span style="font-size: 20px; text-decoration:line-through;" v-if="discount">${{price | formatNumber}}</span>
            </h2>
            <p>
              Aprovecha nuestra oferta especial por tiempo limitado y llévate la
              avanzada tecnología de Iduam. La salud no
              puede esperar, ¡compra ahora y transforma la forma en que proteges
              tu mundo!
            </p>
            <div class="space-40"></div>

            <div class="mt-5" style="display: flex; margin: 0 auto; align-items: center; justify-content: center; flex-direction: column;">
            <v-btn 
                rounded
              style="z-index: 98;position:relative; "
               @click="$router.push('/checkout')"
            >
              Comprar
            </v-btn>
                <img src="../../assets/images/webpay.png" style="width: 100px;margin-top: 12px;" alt="webpay">
            </div>
            
          </div>
        </div>
      </div>
    </section>

    <!--         BENEFICIOS -->
    <div class="container containerbeneficios" style="position:relative;" >
      <section class="Beneficios container">
        <img
          class="Beneficios__Img"
          src="../../assets/images/T-68.png"
          alt="iduam-T-68"
        />

        <div class="Beneficios__Text">
          <h2 class="Beneficios__Text__Title">BENEFICIOS ADICIONALES</h2>
          <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Envío Gratuito:</span> Adquiere tu Iduam hoy y disfruta del envío gratuito
            a tu puerta.
          </p>
            <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Garantía de Satisfacción:</span> Compra con confianza gracias
            a nuestra garantía de satisfacción.
          </p>
          <p class="Beneficios__Text__Paragraph">
            <span style="font-weight: 800;">Soporte Técnico:</span> Nuestro equipo de soporte técnico está
            para ayudarte cuando lo necesites a través de los canales disponibles
          </p>
        </div>
      </section>
    </div>



            <!--Precio-->
      <section class="container" id="pricing">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row">
            <!--begin col md 12 -->
            <div class="col-md-12">
              <div class="luna-headings-dark-center text-center">
                <h2>Precios</h2>

                <h3>¡Tenemos el Plan perfecto para ti!</h3>

                <p>Te cuidamos en todo momento con nuestros servicios.</p>
              </div>
            </div>
            <!--end col md 12 style="width: 50% !important"-->

            <!--begin col md 4 -->
            <div class="col-md-12 col-sm-12">
              <div
                class="luna-pricebox wow fadeInRight"
                data-wow-delay="0.25s"
                style="
                  visibility: visible;
                  animation-delay: 0.25s;
                  animation-name: fadeInRight;
                "
              >
                <div class="price-top">
                  <h4>Iduam T-68</h4>

              <h3
                :class="price ? 'scale-animation' : 'scale-animation-0'"
                style="
                  font-size: 32px;
                  color: #FFFF;
                  margin-top: 8px;
                  transition: all 0.7s ease;
                "
              >
                Ahora
                <span
                  >${{ priceDiscount | formatNumber }}!
                  <span
                    v-if="discount"
                    style="font-size: 20px; text-decoration: line-through"
                    >${{ price | formatNumber }}</span
                  ></span
                >
              </h3>
                </div>

                <div class="price-bottom">
                  <ul>
                    <li>
                      <i class="fa fa-check"></i>Aplicación Móvil y Smartband
                    </li>
                    <li>
                      <i class="fa fa-check"></i>Realizar mediciones manuales y
                      Automáticas tipo Holter
                    </li>
<!--                     <li>
                      <i class="fa fa-check"></i>ECG o Electrocardioagrama con
                      interpretación de Inteligencia Artificial (IA)
                    </li> -->
                    <li>
                      <i class="fa fa-check"></i>Historial de mediciones en la
                      aplicación móvil y en el Portal Web
                    </li>
                    <li class="last">
                      <i class="fa fa-check"></i>Red de Seguridad y Emergencia
                      Personal (5 usuarios)
                    </li>
                    <!--li class="last"><i class="fa fa-close"></i>Consulta online</li-->
                  </ul>

                <button
                  style="z-index: 98"
                  @click="$router.push('/checkout')"
                  class="main-btn"
                >
                  Comprar
                </button>
                </div>
              </div>
            </div>
    </div>
        </div>
        </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/commons/Header.vue";
import Footer from "@/components/commons/Footer.vue";
import { getCartData } from '../../helpers/api/pagos'

export default {
  components: { Header, Footer },
  data() {
    return {
              features: [
/*         {
          number: 1,
          icon: "mdi-heart-pulse",
          title: "Monitorización electrocardiográfica",
          paragraph:
            "Realízate tus electrocardiográmas desde el App de Iduam el que será interpretado en forma preventiva por un algoritomo de inteligencia artificial.",
        }, */
        {
          number: 1,
          icon: "mdi-heart",
          title: "Ritmo Cardíaco",
          paragraph:
            "Conoce tu ritmo cardíaco de manera muy simple desde el App de Iduam, desde ahora no necesitarás movilizarte a ningún hospital o centro médico.",
        },
        {
          number: 2,
          icon: "mdi-water",
          title: "Presión Arterial",
          paragraph:
            "Realízate en un instante tu prueba de presión arterial desde nuestra Aplicación móvil Iduam.",
        },
        {
          number: 3,
          icon: "mdi-gauge",
          title: "Saturación de Oxígeno",
          paragraph:
            "Usando las Smartband de Iduam podrás saber al instante tu saturación de oxígeno en la sangre.",
        },
        {
          number: 4,
          icon: "mdi-video",
          title: "Monitoreo",
          paragraph:
            "Levanta en tiempo real tu monitoreo de tu imagen y podrás ser visualizado en un panel web remotamente cuando nuestra empresa ofrezca dicho servicio.",
        },
        {
          number: 5,
          icon: "mdi-shield",
          title: "Red de Seguridad",
          paragraph:
            "Genera tu red de emergencia de salud personal la cual ante una alerta se podrán comunicar por video conferencia para prestarte la ayuda que necesites.",
        },
        {
          number: 6,
          icon: "mdi-chart-bar",
          title: "Historial",
          paragraph:
            "Revisa en cualquier momento tu historial de mediciones médicas desde tu celular o desde nuestro administrador web.",
        },
      ],
      code: null,
      price: null,
      priceDiscount: '',
      discount: false
    };
  },
  methods: {
    getCartData() {
      const products = this.$store.state.products.map(product => ({
            ...product,
            id: product.product_id
            }));
      const data =  {products, coupon_code: this.$store.state.discountCode}
      this.discount = true
      if (!this.$store.state.discountCode) {
        delete data.coupon_code
        this.discount = false
      }

      getCartData(data).then(res => {
        this.priceDiscount = res.cart_data.to_pay
        this.price = res.cart_data.total
    })
    }
  },
  mounted() {
    this.getCartData()
  },
  filters: {
    formatNumber: function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style lang="scss" scoped>
/*     @import '../../assets/css/style.css';  */
@import "../../assets/css/normalize.css";
/*     @import '../../assets/css/bootstrap-min.css'; */
@import "../../assets/css/owl-carousel-min.css";
@import "../../assets/css/lity-min.css";
@import "../../assets/css/font-awesome-min.css";
@import "../../assets/css/animate.css";
@import "../../assets/css/theme.css";
@import "../../assets/css/helper.css";
@import "../../assets/css/responsive.css";

.title,
.rate {
  color: #0064f1;
}
.bttn-1 {
  color: #fff;
  &:hover {
    color: #0064f1;
  }
}
.bttn-3 {
  color: #0064f1;
  &:hover {
    color: #fff;
  }
}

.bg-camara {
  /*   background-image: url("../../assets/images/image-box-bg.png"); */
  background-size: cover;
}

.about-section {
  background-image: url("../../assets/images/image.png");
}
.bttn-2:hover {
  color: #0064f1 !important;
}
.top-title {
  font-size: 32px;
  color: rgb(46, 0, 0);
}
.title {
  font-size: 36px !important;
}
@media (max-width: 568px) {
  .intro-area {
    background-size: cover !important;
  }
  .top-title {
    color: #fff;
  }
}

.box-bg {
  background-image: url("../../assets/images/image-box-bg.png");
  background-size: cover;
}
.main-btn {
  background-color: #0064f1;
  border-radius: 25px;
  color: #fff;
  padding: 12px 50px;
  box-shadow: 0px 3px 6px #00000029;
  &:hover {
    background-color: #fff;
    color: #0064f1;
  }
}

@media (min-width: 768px) {
  .main-btn {
    height: max-content;
  }
}

.hr {
    border: 3px solid #BFC0C0; width: 194px;
    margin-left: 20px;
}
@media (min-width: 768px) {
    .hr {
        margin-left: 0;
    }
}

///////////////////////////////////////////////////////////////////////////
//hero
.hero-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 20px;
}
@media (min-width: 768px) {
  .hero-buttons-container {
    flex-direction: row;
  }
}

//POR QUÉ
//mobile
.Porque {
    padding-top: 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff;
  border-radius: 28px;
  opacity: 1;
  /* backdrop-filter: blur(50px);
-webkit-backdrop-filter: blur(50px); */
  padding-bottom: 20px;
  &__Title {
    text-transform: uppercase;
    text-align: center;
    color: #0064f1;
  }
  &__Container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    &__Item {
      text-align: center;
      &__Number {
        color: #0064f1;
        font-weight: 800;
        font-size: 46px;
        margin-top: 10px;
      }
      &__Icon {
        color: #0064f1;
        font-size: 56px;
        margin-left: 8px;
      }
      &__Title {
        color: #0064f1;
      }
      &__Paragraph {
        color: #000;
      }
    }
  }
}
.container-title-item {
  display: flex;
  justify-content: center;
}

//desktop
@media (min-width: 768px) {
  .Porque {
    padding-top: 0;
    &__Title {
      margin-top: 40px;
      font-size: 32px;
      font-weight: 800;
    }
    &__Container {
      margin-top: 40px;
      justify-content: center;
      gap: 20px;
      &__Item {
        text-align: left;
        width: 340px;
        &--button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__Number {
        }
        &__Icon {
          font-size: 46px;
        }
        &__Title {
        }
        &__Paragraph {
        }
      }
    }
  }
  .container-title-item {
    justify-content: flex-start;
  }
}
//OFERTA
.oferta-bg {
  background-size: 1000% 100% !important;
}

.Oferta {
  &__Img {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__Content {
    text-align: center;
  }
}

@media (min-width: 768px) {
    .Oferta {
        &__Content  p{
        margin: 0 auto;
        max-width: 740px;
        }
    }

  .oferta-bg {
    margin-top: 80px;
    background-size: 170% 150% !important;
  }
  .oferta-container {
    padding-bottom: 100px;
  }
}

.play-bttn {
  position: absolute;
  transform: translate(50%, 50%);
  top: 21%;
  left: 40%;
  color: #fff;
  font-size: 60px;
}

@media (min-width: 768px) {
  .play-bttn {
    left: 47%;
  }
}

// BENEFICIOS

.Beneficios {
  margin-top: 60px;
  text-align: center;
  padding: 0 20px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border: 1px solid #e4e4e4;
  border-radius: 62px;
  display: flex;
  gap: 32px;
  &__Text {
    &__Title {
      color: #0064f1;
    }
  }
  &__Img {
    max-width: 180px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .Beneficios {
    position: absolute;
   /* bottom: -180px;*/
    display: flex;
    align-items: center;
    &__Text {
      width: 60%;
      text-align: left;
      &__Title {
      }
      &__Paragraph {
        margin-top: 12px;
      }
    }

    &__Img {
    }
  }
}
@media (min-width: 768px) {
    .containerbeneficios {
        height: 220px;
    }
    }


.scale-animation {
  transform: scale(1);
}
.scale-animation-0 {
    transform: scale(0);
}
</style>
